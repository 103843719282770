import * as React from "react";
import Layout from "../components/layout";
import Top10Posts from "../components/top10Posts";

const Projects = () => {
  return (
    <Layout
      pageTitle="Personal Projects"
      description="Daniel Jose Da Silva personal projects. Public OKR, Fast Feedback and more coming soon."
      keywords="personal projects,okr,feedback,fast feedback,daniel,daniel da silva,daniel jose da silva"
    >
      <Top10Posts />
    </Layout>
  );
};

export default Projects;
